<template>
    <div class="home img-home">
        <div class="my-flex p5vm">
            <div class="top-icon">               
                <i @click="logout()" class="el-icon-lock color-oq"></i>
            </div>

            <div class="content-block">
                <div class="flex-1">
                    <router-link
                        :to="{ name: 'AuthLayoutRefa' }"
                        class="oahref"
                    >
                        <div class="itme-card my-card img3">
                            <img
                                class="home-icon"
                                src="./../../public/img/clipboard.png"
                                alt=""
                            />
                            <span>{{ $t('message.REFA') }}</span>
                        </div>
                    </router-link>
                    <router-link
                        :to="{ name: 'AuthLayoutControl' }"
                        class="oahref"
                    >
                        <div class="itme-card my-card img4">
                            <img
                                class="home-icon"
                                src="./../../public/img/medal.png"
                                alt=""
                            />
                            <span>{{ $t('message.Quality control') }}</span>                            
                        </div>
                    </router-link>
                </div>
                <div class="user-log">
                    <img
                        src="./../../public/img/default-avatar.jpg"
                        alt=""
                    />
                    <div class="u-name">{{ auth_username }}</div>
                    <div class="u-doljan">{{ role ? role.name : '' }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "home",
    components: {},
    computed: {
        ...mapGetters({
            auth_username: 'auth/name',
            role: 'auth/role'
        })
    },
    methods: {
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>
